<template>
    <div class="qr-styles">
        <div v-if="isPro" class="d-flex justify-space-between align-center mb-4">
            <div class="font-weight-medium smfm-pro-heading">
                {{ $t('app.setupstyle') }}
            </div>
        </div>
        <v-expansion-panels accordion v-model="expandedpanel" ref="panels"
            class="qr-settings" @change="onPanelExpand">
            <!-- Design ideas -->
            <v-expansion-panel v-show="!ispreseteditor">
                <v-expansion-panel-header>
                    <div class="d-flex align-center text-body-1 font-weight-medium">
                        <v-icon class="me-3">{{ iconAutoFix }}</v-icon>
                        {{ isPro ? $t('qrstyle.preset') : $t('qrstyle.designideas') }}
                        <article-link v-if="isPro" article="about_presets" class="ms-3" :show-title="true">
                            <v-icon class="info-icon">{{ iconInfo }}</v-icon>
                        </article-link>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager class="pa-0 elevation-0">
                    <imageselect name="config[preset]"
                        :asbutton="true"
                        :key="`imselpresets-${presetoptionscounter}`"
                        :options="presetoptions" :size="120"
                        v-model="config.preset" @input="applyPreset" ref="preset">
                    </imageselect>
                    <pro-save-preset v-if="isPro" :config="config">
                        <template v-slot:activator="{open}">
                            <v-btn class="smfm-light-green-btn smfm-md-btn mt-4" @click="open">
                                {{ $t('qrstyle.saveasapreset') }}
                            </v-btn>
                        </template>
                    </pro-save-preset>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Style -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="d-flex align-center text-body-1 font-weight-medium">
                        <v-icon class="me-3">{{ iconQrstyle }}</v-icon>
                        {{ $t('qrstyle.style') }}
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager class="pa-0">
                    <imageselect name="config[style]" class="mb-4"
                        :options="coptions.style" v-model="config.style" 
                        @input="e=>up('style',e)" ref="style">
                    </imageselect>

                    <v-checkbox dense name="config[eyestyle]" 
                        :label="$t('qrstyle.customeyes')" v-model="config.eyestyle" @change="e=>up('eyestyle',e)">
                    </v-checkbox>

                    <div v-if="config.eyestyle">
                        <div class="text-body-1 mb-2 eyestyle-title">
                            {{ $t('qrstyle.outside') }}:
                        </div>
                        <imageselect name="config[eyeoutline]" :options="coptions.eyeoutline" class="mb-2" 
                            v-model="config.eyeoutline" ref="eyeoutline" @input="e=>up('eyeoutline',e)">
                        </imageselect>
                        <div class="text-body-1 mb-2 eyestyle-title">
                            {{ $t('qrstyle.inside') }}:
                        </div>
                        <imageselect name="config[eyeball]" :options="coptions.eyeball" class="mb-2" 
                            v-model="config.eyeball" ref="eyeball" @input="e=>up('eyeball',e)">
                        </imageselect>
                    </div>

                    <div>
                        <div class="d-flex align-center">
                            <p class="font-weight-medium mb-0 me-3">{{ $t('qrstyle.errorlevel') }}</p>
                            <article-link article="about_logos" variant="2" :show-title="true">
                                <v-icon>{{ iconInfo }}</v-icon>
                            </article-link>
                        </div>
                        <v-radio-group v-model="config.errorlevel" 
                            hide-details class="mt-2"
                            @change="e=>up('errorlevel',e)">
                            <v-radio v-for="t in errorleveloptions" :key="'errorlevel_'+t.value"
                                dense :label="t.label" :value="t.value">
                            </v-radio>
                        </v-radio-group>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Template -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="d-flex align-center text-body-1 font-weight-medium">
                        <v-icon class="me-3">{{ iconTemplate }}</v-icon>
                        {{$t('qrstyle.template')}}
                        <article-link article="about_templates" class="ms-3" :show-title="true">
                            <v-icon class="info-icon">{{ iconInfo }}</v-icon>
                        </article-link>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager class="pa-0">
                    <imageselect name="config[template]"
                        :key="`imseltempl-${templateoptionscounter}`"
                        :options="templateoptions" :size="100"
                        v-model="config.template" @input="e=>up('template',e)" ref="template">
                    </imageselect>
                    <qr-template-captions v-if="hastemplate" :templateimage="templateimage" 
                        v-model="config.captions" @change="e=>up('captions',e)">
                    </qr-template-captions>
                    <input-file v-if="isPro"
                                :text="$t('pro.library.templates.uploader')"
                                :dense="true"
                        @change="uploadedTemplate">
                    </input-file>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Logo -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="d-flex align-center text-body-1 font-weight-medium">
                        <v-icon class="me-3">{{ iconFlower }}</v-icon>
                        {{$t('qrstyle.logo')}}
                        <article-link article="about_logos" class="ms-3" :show-title="true">
                            <v-icon class="info-icon">{{ iconInfo }}</v-icon>
                        </article-link>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager class="pa-0">
                    <imageselect name="config[logo]"
                        :key="`imsellogos-${logooptionscounter}`"
                        :options="logooptions"
                        v-model="config.logo" @input="e=>up('logo',e)" ref="logo" class="mb-4">
                    </imageselect>
                    <input-file v-if="isPro"
                                :text="$t('pro.library.logos.uploader')" :dense="true"
                                @change="uploadedLogo"></input-file>
                    <!-- Customise logo positioning -->
                    <div v-if="haslogo">
                        <v-checkbox dense name="config[logopositioning]" 
                            :label="$t('qrstyle.logopositioning')" 
                            v-model="config.logopositioning" @change="e=>up('logopositioning',e)">
                        </v-checkbox>
                    </div>
                    <div v-if="haslogo && config.logopositioning">
                        <radios name="config[logobehind]" 
                            :options="options.logobehind" v-model="config.logobehind" 
                            @input="e=>up('logobehind',e)">
                        </radios>
                        <!-- Logo size -->
                        <v-row dense>
                            <v-col cols="12" sm="10" md="8">
                                <v-slider class="no-v-messages" v-model="config.logosize" hide-details
                                    :label="$t('qrstyle.logosize')" max="150" min="0" @change="e=>up('logosize',e)">
                                    <template v-slot:append>
                                        <v-text-field v-model="config.logosize" type="number"
                                            hide-details single-line dense 
                                            style="width: 65px; margin-top: -5px" @change="e=>up('logosize',e)" suffix="%"/>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <!-- Logo x -->
                        <v-row dense>
                            <v-col cols="12" sm="10" md="8">
                                <v-slider class="no-v-messages" v-model="config.logox" hide-details
                                    :label="$t('qrstyle.logox')" max="120" min="-20" @change="e=>up('logox',e)">
                                    <template v-slot:append>
                                        <v-text-field v-model="config.logox" type="number"
                                            hide-details single-line dense 
                                            style="width: 65px; margin-top: -5px" suffix="%" @change="e=>up('logox',e)"/>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <!-- Logo y -->
                        <v-row dense class="mb-3">
                            <v-col cols="12" sm="10" md="8">
                                <v-slider class="no-v-messages" v-model="config.logoy" hide-details
                                    :label="$t('qrstyle.logoy')" max="120" min="-20" @change="e=>up('logoy',e)">
                                    <template v-slot:append>
                                        <v-text-field v-model="config.logoy" type="number"
                                            hide-details single-line dense 
                                            style="width: 65px; margin-top: -5px" @change="e=>up('logoy',e)" suffix="%"/>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-btn text class="smfm-reset-btn px-0" @click="resetLogoPositioning() && up('logosize', config.logosize)">
                            <v-icon class="me-2">{{ iconReset }}</v-icon>
                            {{ $t('qrstyle.resettodefault') }}
                        </v-btn>
                    </div>
                    <!-- Customise how blocks in the middle are rendered -->
                    <v-checkbox dense name="config[gapcustom]" :label="$t('qrstyle.gap')" 
                        v-model="config.gapcustom" @change="e=>up('gapcustom',e)">
                    </v-checkbox>
                    <div v-if="config.gapcustom">
                        <imageselect name="config[gap]" :options="coptions.gap" v-model="config.gap" 
                            @input="e=>up('gap',e)" ref="gap" class="mb-4">
                        </imageselect>
                        <!-- Gap width -->
                        <v-row dense>
                            <v-col cols="12" sm="10" md="8">
                                <v-slider class="no-v-messages" v-model="config.gapwidth" hide-details
                                    :label="$t('qrstyle.gapwidth')" max="100" min="0" @change="e=>up('gapwidth',e)">
                                    <template v-slot:append>
                                        <v-text-field v-model="config.gapwidth" type="number"
                                            hide-details single-line dense 
                                            style="width: 65px; margin-top: -5px" @change="e=>up('gapwidth',e)" suffix="%"/>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <!-- Gap height -->
                        <v-row dense class="mb-3">
                            <v-col cols="12" sm="10" md="8">
                                <v-slider class="no-v-messages" v-model="config.gapheight" hide-details
                                    :label="$t('qrstyle.gapheight')" max="100" min="0" @change="e=>up('gapheight',e)">
                                    <template v-slot:append>
                                        <v-text-field v-model="config.gapheight" type="number"
                                            hide-details single-line dense 
                                            style="width: 65px; margin-top: -5px" @change="e=>up('gapheight',e)" suffix="%"/>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-btn text class="smfm-reset-btn px-0" @click="resetGap">
                            <v-icon class="me-2">{{ iconReset }}</v-icon>
                            {{ $t('qrstyle.resettodefault') }}
                        </v-btn>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Colors -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="d-flex align-center text-body-1 font-weight-medium">
                        <v-icon class="me-3">{{ iconPalette }}</v-icon>
                        {{ $t('qrstyle.colors') }}
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager class="pa-0">
                    <imageselect name="config[fill]" :options="coptions.fill" class="mb-4"
                        v-model="config.fill" @input="e=>up('fill',e)" ref="fill">
                    </imageselect>
                    <!-- Fill style -->
                    <div class="text-body-1 eyestyle-title">
                        {{ $t('qrstyle.fill') }}:
                    </div>
                    <div class="d-flex flex-row mt-2 flex-wrap">
                        <div class="me-3 mb-5">
                            <input-color name="config[color]" 
                                :label="$t('qrstyle.color1')" v-model="config.color" 
                                @input="e=>up('color',e)">
                            </input-color>
                        </div>
                        <div class="me-3 mb-5" v-if="config.fill !== 'normal'">
                            <input-color name="config[color2]" 
                                :label="$t('qrstyle.color2')" v-model="config.color2" 
                                ref="color2" @input="e=>up('color2',e)">
                            </input-color>
                        </div>
                        <div class="me-3 mb-5" v-if="config.fill === 'multi'">
                            <input-color name="config[color3]" 
                                :label="$t('qrstyle.color3')" v-model="config.color3" 
                                ref="color3" @input="e=>up('color3',e)">
                            </input-color>
                        </div>
                        <div class="me-3 mb-5" v-if="config.fill === 'multi'">
                            <input-color name="config[color4]" 
                                :label="$t('qrstyle.color4')" v-model="config.color4" 
                                ref="color4" @input="e=>up('color4',e)">
                            </input-color>
                        </div>
                    </div>
                    <!-- Background color -->
                    <div class="text-body-1 eyestyle-title">
                        {{ $t('qrstyle.bgcolor') }}:
                    </div>
                    <v-radio-group row v-model="config.bgcolor"
                        hide-details class="mt-2"
                        @change="e=>up('bgcolor',e)">
                        <v-radio :value="bgcolorRadioBtn" class="mb-5">
                            <template v-slot:label>
                                <input-color name="config[bgcolor]" 
                                    :label="$t('qrstyle.color')" 
                                    v-model="bgcolorRadioBtn"
                                    @input="onBgcolorChange">
                                </input-color>
                            </template>
                        </v-radio>
                        <v-radio :value="'transparent'" class="mb-5">
                            <template v-slot:label>
                                <div class="d-flex align-center">
                                    <div class="trasparent-color-block"></div>
                                    <div class="text-body-1 ps-2">{{ $t('qrstyle.transparent')}}</div>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <!-- Template color -->
                    <div v-if="hastemplate">
                        <v-checkbox dense name="config[changetemplatecolor]" 
                            :label="$t('qrstyle.templatecolorcustom')" 
                            v-model="config.changetemplatecolor" 
                            @change="e=>up('changetemplatecolor',e)">
                        </v-checkbox>
                        <div v-if="config.changetemplatecolor" class="mb-4">
                            <input-color name="config[templatecolor]" 
                                :label="$t('qrstyle.templatecolor')" 
                                v-model="config.templatecolor" ref="templatecolor" 
                                @input="e=>up('templatecolor',e)">
                            </input-color>
                        </div>
                    </div>
                    <!-- Logo color -->
                    <div v-if="haslogo">
                        <v-checkbox dense name="config[changelogocolor]" 
                            :label="$t('qrstyle.logocolorcustom')" 
                            v-model="config.changelogocolor" 
                            @change="e=>up('changelogocolor',e)">
                        </v-checkbox>
                        <div v-if="config.changelogocolor" class="mb-4">
                            <input-color name="config[logocolor]" 
                                :label="$t('qrstyle.logocolor')" 
                                v-model="config.logocolor" ref="logocolor" 
                                @input="e=>up('logocolor',e)">
                            </input-color>
                        </div>
                    </div>
                    <!-- Customize corner color -->
                    <v-checkbox dense name="config[eyecolor]" 
                        :label="$t('qrstyle.customeyecolor')" v-model="config.eyecolor" 
                        @change="e=>up('eyecolor',e)">
                    </v-checkbox>
                    <div v-if="config.eyecolor" class="d-flex flex-row flex-wrap">
                        <div class="me-3 mb-5">
                            <input-color name="config[coloreyeoutline]" 
                                :label="$t('qrstyle.eyeoutlinecolor')" v-model="config.coloreyeoutline" 
                                ref="coloreyeoutline" @input="e=>up('coloreyeoutline',e)">
                            </input-color>
                        </div>
                        <div class="me-3 mb-5">
                            <input-color name="config[coloreyeball]" 
                                :label="$t('qrstyle.eyeballcolor')" v-model="config.coloreyeball" 
                                ref="coloreyeball" @input="e=>up('coloreyeball',e)">
                            </input-color>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <div class="d-none">
                <v-btn @click="viewConfig">Print config to the console</v-btn>
            </div>
        </v-expansion-panels>

        <v-snackbar v-model="haserror" color="error" timeout="10000">
            {{ errortext }}
            <template v-slot:action="{ attrs }">
                <v-btn color="gray" text v-bind="attrs" @click="setError(null)">{{$t('app.close')}}</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>


<script>
    import { mapActions, mapGetters } from 'vuex'
    import { mdiQrcode, mdiFlowerTulipOutline, mdiPaletteOutline, mdiAutoFix, mdiFormatTextbox, mdiInformation } from '@mdi/js';
    import InputColor from "./InputColor";
    import Radios from "./Radios";
    import Imageselect from "@/components/forms/Imageselect";
    import QrTemplateCaptions from "@/components/forms/QrTemplateCaptions";
    import ArticleLink from "@/components/forms/ArticleLink";
    import customIcons from "@/mixins/customIcons";
    // Note that ProSavePreset and InputFile are not imported here but rather in main-*.js because we want to skip them for demo.

    export default {
        name: 'Qrstyles',
        components: { ArticleLink, QrTemplateCaptions, Imageselect, Radios, InputColor },
        mixins: [ customIcons ],
        props: {
            // Initial index of the expanded section:
            expanded: { default: 0 },
            ispreseteditor: { default: false },
        },
        data: function() {
            return {
                expandedpanel: 0,
                config: {
                    style: 'square',
                    eyestyle: 0,
                    eyeoutline: 'square',
                    eyeball: 'square',
                    errorlevel: '0',
                    logo: '0',
                    fill: 'normal',
                    eyecolor: 0,
                    template: '0',
                    changetemplatecolor: 0,
                    changelogocolor: 0,
                    color: '#000000',
                    color2: '#FF0000',
                    color3: '#3B23A3',
                    color4: '#336F4B',
                    bgcolor: '#FFFFFF',
                    coloreyeoutline: '',
                    coloreyeball: '',
                    templatecolor: '',
                    logocolor: '',
                    logopositioning: 0,
                    logosize: 20,
                    logox: 50,
                    logoy: 50,
                    gapcustom: false,
                    gap: 'none',
                    gapwidth: 30,
                    gapheight: 30,
                    logobehind: 0,
                    captions: {},
                },
                bgcolorRadioBtn: '#FFFFFF',
                lastactualgapwidth: 0,
                lastactualgapheight: 0,
                iconQrcode: mdiQrcode,
                iconFlower: mdiFlowerTulipOutline,
                iconPalette: mdiPaletteOutline,
                iconAutoFix: mdiAutoFix,
                iconTemplate: mdiFormatTextbox,
                options: {
                    logobehind: [this.$t('qrstyle.logofront'), this.$t('qrstyle.logobehind')]
                },
                errortext: null,
                haserror: false,
            }
        },
        created() {
            this.expandedpanel = this.expanded;
            if (this.presetoptions.length) {
                // Apply first shared preset.
                let systemPresets = this.presetoptions.filter(o => !o.usercontent)
                this.__applyPreset(systemPresets.length ? systemPresets[0] : this.presetoptions[0])
            }
        },
        computed: {
            ...mapGetters({logooptions: 'logooptionswithempty', templateoptions: 'templateoptions',
                           presetoptions: 'presetoptions', logooptionscounter: 'logooptionscounter',
                           templateoptionscounter: 'templateoptionscounter',
                           presetoptionscounter: 'presetoptionscounter'}),
            errorleveloptions() {
                return [
                    {value: '0', label: this.$t('qrstyle.errorlevelauto')},
                    {value: 'L', label: this.$t('qrstyle.errorlevellow')},
                    {value: 'M', label: this.$t('qrstyle.errorlevelmedium')},
                    {value: 'Q', label: this.$t('qrstyle.errorlevelquad')},
                    {value: 'H', label: this.$t('qrstyle.errorlevelhigh')},
                ]
            },
            haslogo() {
                return ''+this.config.logo !== '0'
            },
            hastemplate() {
                return ''+this.config.template !== '0'
            },
            logoimage() {
                return this.findByValue(this.logooptions, this.config.logo)
            },
            templateimage() {
                return this.findByValue(this.templateoptions, this.config.template)
            },
            templatecolor() {
                return (this.config.changetemplatecolor && this.config.templatecolor) ? this.config.templatecolor : this.config.color
            },
            logocolor() {
                return (this.config.changelogocolor && this.config.logocolor) ? this.config.logocolor : this.config.color
            },
            iconInfo() { return mdiInformation },
        },
        methods: {
            ...mapActions(['refreshUserLibrary', 'validateUploadedTemplate', 'validateUploadedLogo', 'getUploadedFileSize']),
            u(v) {
                var res = {}
                v.forEach(row => res[row.value] = row.label)
                return res
            },

            findByValue(options, value) {
                const f = (options && options.length && `${value}` !== '0') ? options.filter(v => v.value === value) : []
                return f.length ? f[0] : null
            },

            onPanelExpand(panelidx) {
                // console.log('onPanelExpand')
                // console.log(panelidx)
                if (panelidx === 0) this.repositionImageSelects(['preset'])
                if (panelidx === 1) this.repositionImageSelects(['style', 'eyeoutline', 'eyeball'])
                if (panelidx === 2) this.repositionImageSelects(['template'])
                if (panelidx === 3) this.repositionImageSelects(['logo', 'gap'])
                if (panelidx === 4) this.repositionImageSelects(['fill'])
            },

            showTemplatesSection() {
                // TODO remove this function and all usages.
                this.expandedpanel = 2
            },

            logoimagedata() {
                const filtered = this.haslogo ? this.logooptions.filter(v => v['value'] === this.config.logo) : []
                if (!(filtered && filtered.length)) {
                    return {
                        width: 100,
                        height: 100,
                        gap: 'none',
                        removemargin: 0
                    }
                }
                let dataset = filtered[0], width = dataset.width, height = dataset.height
                if (!width || !height) {
                    width = 100 * (dataset.width2height ? dataset.width2height : 1)
                    height = 100
                }
                return {
                    width,
                    height,
                    gap: dataset.gap ? dataset.gap : 'none',
                    removemargin: dataset.removemargin ? dataset.removemargin : 0
                }
            },

            repositionImageSelects(names) {
                names.forEach(name => this.$refs[name] && this.$refs[name].reposition())
            },

            resetLogoPositioning() {
                let old = {size: this.config.logosize, x: this.config.logox, y: this.config.logoy},
                    gp = this.calculateRecommendedGap(),
                    margin = gp.removemargin/100
                if (this.lastactualgapheight && this.lastactualgapwidth) {
                    const ratio = this.haslogo ? this.logoimage.height / this.logoimage.width : 1,
                          relsize = Math.min(this.lastactualgapwidth * (1-margin), this.lastactualgapheight / ratio * (1-margin))
                    this.config.logosize = relsize.toFixed(1)
                } else {
                    this.config.logosize = (gp.gapwidth * (1-margin)).toFixed(1)
                }
                this.config.logox = 50
                this.config.logoy = 50
                return old.size !== this.config.logosize || old.x !== this.config.logox || old.y !== this.config.logoy
            },

            resetGap() {
                this.config.gapcustom = 0
                this.up('gapcustom', 0)
                this.config.gapcustom = 1
                this.up('gapcustom', 1)
            },

            onBgcolorChange(event) {
                this.config.bgcolor = event;
                this.up('bgcolor', event);
            },

            up(el, value) {
                //console.log('updated el '+el+' with value '+value)

                // When switching to "custom corners color" set both colors to primary color (if they were previously empty)
                // When switching off, set them to null
                if (el === 'eyecolor') {
                    this.config.coloreyeoutline = value ? (this.config.coloreyeoutline || this.config.color) : null
                    this.config.coloreyeball = value ? (this.config.coloreyeball || this.config.color) : null
                }

                // When changing the 'fill' to gradient/dot/multi - automatically fill the missing colors with the primary color
                if (el === 'fill' && value !== 'normal') {
                    this.config.color2 = this.config.color2 || this.config.color
                }
                if (el === 'fill' && value === 'multi') {
                    this.config.color3 = this.config.color3 || this.config.color
                    this.config.color4 = this.config.color4 || this.config.color
                }

                // When customising template/logo color and the initial value of the color was not changed - fill it with primary color
                if (el === 'changetemplatecolor') {
                    this.config.templatecolor = value ? (this.config.templatecolor || this.config.color) : null
                }
                if (el === 'changelogocolor') {
                    this.config.logocolor = value ? (this.config.logocolor || this.config.color) : null
                }

                // When changing the style, automatically adjust the selection for the eyeoutline/ball styles to recommended
                if ((el === 'style' || el === 'eyestyle') && !this.config.eyestyle) {
                    var data = this.$refs.style.dataset
                    this.config.eyeoutline = data.eyeoutline
                    this.config.eyeball = data.eyeball
                }

                // When eyeoutline/eyeball become visible, we need to make sure the selected is centered
                if (el === 'eyestyle' && this.config.eyestyle) {
                    this.repositionImageSelects(['eyeoutline', 'eyeball'])
                }

                // Calculate gaps
                let gp = this.calculateRecommendedGap()
                if (el === 'gapcustom' || (el === 'logo' && !this.config.gapcustom)) {
                    this.config.gap = gp.gap
                    this.config.gapwidth = gp.gapwidth
                    this.config.gapheight = gp.gapheight
                }
                this.config.removemargin = gp.removemargin
                if (el === 'gapcustom' && value) {
                    this.repositionImageSelects(['gap'])
                }

                if (el === 'logopositioning' || (el === 'logo' && !this.config.logopositioning)) {
                    this.resetLogoPositioning()
                }

                this.$emit('change', this.config)
            },

            setConfig(config) {
                this.config = Object.assign(this.config, config);
                this.$nextTick(() => {
                    this.config.captions = { ...config.captions };
                });
            },

            getErrorLevel() {
                if (['L', 'M', 'Q', 'H'].includes(this.config.errorlevel)) return this.config.errorlevel
                return this.haslogo ? 'H' : 'M'
            },

            getErrorCorrectionCapability(errorlevel) {
                if (errorlevel === 'L') { return 0.07 }
                if (errorlevel === 'Q') { return 0.25 }
                if (errorlevel === 'H') { return 0.30 }
                // Default 'M'
                return 0.15
            },

            calculateRecommendedGap() {
                const data = this.logoimagedata()

                let maxremovedwidth = 20, maxremovedheight = 20
                let empty = this.getErrorCorrectionCapability(this.getErrorLevel()) * 0.5 - 0.01,
                    mw = 25,
                    mh = 25, // TODO we don't really know the matrix size
                    lw = parseFloat(data.width),
                    lh = parseFloat(data.height),
                    area = mw * mh - 8*8*3,
                    margin = parseFloat(data.removemargin),
                    lhnew, lwnew, ratio
                if (lh < lw) {
                    lhnew = lh / (100-margin)*100
                    lwnew = lw + lhnew - lh
                } else {
                    lwnew = lw / (100-margin)*100
                    lhnew = lh + lwnew - lw
                }

                ratio = lwnew / lhnew
                maxremovedwidth = Math.sqrt(empty * area * ratio)
                maxremovedheight = maxremovedwidth / ratio

                if (maxremovedwidth > mw) {
                    maxremovedwidth = mw
                    maxremovedheight = maxremovedwidth / ratio
                }

                if (maxremovedheight > mh) {
                    maxremovedheight = mh
                    maxremovedwidth = maxremovedheight * ratio
                }

                return {
                    gap: data.gap,
                    gapwidth: parseInt(maxremovedwidth / mw * 1000)/10,
                    gapheight: parseInt(maxremovedheight / mh * 1000)/10,
                    logosize: parseInt(maxremovedwidth / mw * 1000)/10, // Because they depend on the actual gap that will be returned by the API
                    removemargin: margin
                }
            },

            calculateLogoPosition(qrwidth, qrheight, actualgapwidth, actualgapheight) {
                let ratio = this.haslogo ? this.logoimage.height / this.logoimage.width : 1,
                    rel
                this.lastactualgapwidth = actualgapwidth
                this.lastactualgapheight = actualgapheight
                if (actualgapheight !== 0) {
                    let margin = this.config.removemargin/100,
                        width = Math.min(actualgapwidth/100 * (1-margin), actualgapheight/100 / ratio * (1-margin))
                    rel = {w: width, h: width * ratio, x: 0.5, y: 0.5}
                } else {
                    rel = {w: this.config.gapwidth/100, h: this.config.gapwidth * ratio/100, x: 0.5, y: 0.5}
                }
                if (this.config.logopositioning) {
                    rel = {w: this.config.logosize/100, h: this.config.logosize*ratio/100, x: this.config.logox/100, y: this.config.logoy/100}
                }
                return {
                    x: ((rel.x - rel.w / 2) * qrwidth).toFixed(3),
                    y: ((rel.y - rel.h / 2) * qrheight).toFixed(3),
                    w: (rel.w * qrwidth).toFixed(3),
                    h: (rel.h * qrheight).toFixed(3)
                }
            },

            /**
             *
             * @param {String} type - either 'ball' or 'outline'
             * @param {String} corner - either 'topleft', or 'topright', or 'bottomleft'
             * @param {String} defaultColor - value to return for not customised corner colors
             * @return {string|*}
             */
            getEyeColor(type, corner, defaultColor) {
                if (this.config.eyecolor) {
                    if (type === 'outline' && this.config.coloreyeoutline) return this.config.coloreyeoutline
                    if (type === 'ball' && this.config.coloreyeball) return this.config.coloreyeball
                }
                if (this.config.fill === 'multi' && corner === 'topright') return this.config.color2
                if (this.config.fill === 'multi' && corner === 'bottomleft') return this.config.color4
                return defaultColor
            },

            viewConfig() {
                // eslint-disable-next-line
                console.log(JSON.stringify(this.config))
            },

            applyPreset(e) {
                const preset = this.findByValue(this.presetoptions, e)
                if (preset) {
                    this.__applyPreset(preset)
                    this.$emit('change', this.config)
                }
            },

            __applyPreset(preset) {
                // Set all values in preset one by one so they trigger respective watchers and update input fields.
                for (let i in preset.config) {
                    if (typeof this.config[i] === 'object') {
                        for (let j in preset.config[i]) {
                            this.config[i][j] = preset.config[i][j]
                        }
                    } else {
                        this.config[i] = preset.config[i]
                    }
                }
            },

            setError(t) {
                this.errortext = t
                this.haserror = !!t
            },

            uploadedLogo(e) {
                if (!e.file) return
                let file, sizedata

                this.validateUploadedLogo(e.file)
                    .then(() => this.getUploadedFileSize(e.file))
                    .then((r) => sizedata = r)
                    .then(() => e.upload('pro/library/logos'))
                    .then(resp => {
                        file = resp
                        return this.refreshUserLibrary({section: 'logo', uploaded: file['name']})
                    }).then(() => {
                        let filtered = this.logooptions.filter(v => v['value'] === file['name'])
                        if (filtered && filtered.length) {
                            filtered[0].width2height = sizedata.width / sizedata.height
                            this.config.logo = file['name']
                            this.up('logo', file['name'])
                        }
                    })
                    .catch(msg => this.setError(msg))
                    .finally(() => e.reset())
            },

            uploadedTemplate(e) {
                if (!e.file) return
                let file

                this.validateUploadedTemplate(e.file)
                    .then(() => e.upload('pro/library/templates'))
                    .then(resp => {
                        file = resp
                        return this.refreshUserLibrary({section: 'template', uploaded: file['name']})
                    })
                    .then(() => {
                        let filtered = this.templateoptions.filter(v => v['value'] === file['name'])
                        if (filtered && filtered.length) {
                            this.config.template = file['name']
                            this.up('template', file['name'])
                        }
                    })
                    .catch(msg => this.setError(msg))
                    .finally(() => e.reset())
            },
        },
    }
</script>

<style lang="scss">
.qr-styles {
    .v-expansion-panel {
        border: 1px solid #EEEEEE;
        border-radius: 10px;
        margin-bottom: 10px;
        &:hover,
        &.v-expansion-panel--active {
            border-color: #00ADB5;
        }
    }
    .v-expansion-panel-header {
        height: 60px;
        background-color: #fff !important;
        color: #393E46;
        padding: 17px 24px;
        border-radius: 10px;
        .v-icon:not(.info-icon) {
            color: #03949A !important;
        }
        .text-body-1 {
            .v-icon:not(.info-icon) {
                color: #393E46 !important;
            }
        }
        &.v-expansion-panel-header--active {
            min-height: 60px;
            background-color: #00ADB5 !important;
            color: #fff;
            border-radius: 10px 10px 0px 0px;
            .v-icon {
                color: #fff !important;
            }
            .text-body-1 {
                .v-icon:not(.info-icon) {
                    color: #fff !important;
                }
            }
        }
        @media(max-width: 960px) {
            height: 48px;
            padding: 8px 18px;
            &.v-expansion-panel-header--active {
                min-height: 48px;
            }
        }
    }
    .v-expansion-panels {
        .v-expansion-panel:not(:first-child) {
            &:after {
                border-color: transparent;
            }
        }
    }
    .eyestyle-title,
    .no-v-messages label {
        color: #909295;
    }
    .trasparent-color-block {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        background-image: url('/images/transparent-small.svg');
        border: 1px solid #CECECE;
        border-radius: 6px;
    }
    .v-input.v-input--checkbox {
        width: fit-content;
    }
}
</style>
